import '../App.css';
import React, { useEffect, useState } from 'react'
import { HeaderLogoLeft } from '../Logo';
import { withRouter } from 'react-router';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import {listAbouts} from '../graphql/queries'
import ReactMarkdown from 'react-markdown'

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const About = () => {
  const [aboutBlurb, setBlurb] = useState([])
  const [aboutContent, setContent] = useState([])
  const [aboutFounderStatement, setFounderStatement] = useState([])

  const image1Path = '/image1.jpg'
  const image2Path = '/image2.jpg'

  useEffect(() => {
    fetchBlurb()
    fetchContent()
    fetchFounderStatement()
  }, [])

  async function fetchBlurb() {
    try {
      const aboutData = await API.graphql(graphqlOperation(listAbouts, {
        filter: {
            type: {
                eq: "BLURB"
            }
        }
      }))
      const abouts = aboutData.data.listAbouts.items
      setBlurb(abouts[0].text)
    } catch (err) { 
      console.log('error fetching about blurb data') 
      setBlurb('"Leveling the playing field ... one ball at a time"')
    }
  }

  async function fetchContent() {
    try {
      const aboutData = await API.graphql(graphqlOperation(listAbouts, {
        filter: {
            type: {
                eq: "CONTENT"
            }
        }
      }))
      const abouts = aboutData.data.listAbouts.items
      setContent(abouts[0].text)
    } catch (err) {
      console.log('error fetching about content data')
      setContent('Balls4Kids is a nonprofit that provides children in underserved communities with soccer balls, equipment, and mentorship to lead happy, healthy, lives.  Our “goal” is to level the playing field, one ball at a time.  Balls 4 Kids is registered in the state of New York as a limited liability corporation. We are currently in process for 501c(3) tax exemption.')
    }
  }

  async function fetchFounderStatement() {
    try {
      const aboutData = await API.graphql(graphqlOperation(listAbouts, {
        filter: {
            type: {
                eq: "FOUNDER_STATEMENT"
            }
        }
      }))
      const abouts = aboutData.data.listAbouts.items
      setFounderStatement(abouts[0].text)
    } catch (err) {
      console.log('error fetching about founder statement data')
      setFounderStatement("“Soccer is a universal language. Nothing brings people together like kicking a ball around, any time, anywhere. I’ve been playing soccer for over 20 years, and nothing brings me greater joy. The happiness I feel with a ball is not meant for me alone, I must share it with others. The reason I founded Balls 4 Kids is to give the gift of happiness with those who need it the most”  \- Nicholas Marshall")
    }
  }

  return (
    <>
        <div id="about-header">
            <HeaderLogoLeft /> <h1 className="about-blurb">
                {aboutBlurb}
            </h1>
        </div>
        <div id="about-content">
        <div className="about-section-1">
        <img src={image1Path} ></img>
          <ReactMarkdown>{aboutContent}</ReactMarkdown>
        </div>
        <div className="about-section-2">
        <img src={image2Path} ></img>
        <ReactMarkdown id="founders-statement">
          {aboutFounderStatement}
        </ReactMarkdown>
        </div>
        </div>
    </>
  );
}

const aboutClassWithHistory = withRouter(About);
export {aboutClassWithHistory as AboutPage};
export {About};
